import React from 'react'
import './styles.css'
import PropTypes from 'prop-types'; // Импорт библиотеки PropTypes
import { Link } from 'react-router-dom';

const TextOnGreenFieldComponent = (props) => {
  return (
    <div className="TextOnGreenFieldBlock card col-md-7">
      <h1>
        {props.title}
      </h1>
      <span>
        {props.text}
      </span>        
    </div>
  )
}

TextOnGreenFieldComponent.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

const TextParagraphComponent = ({text, className}) => {
  return (
    <div className={`TextParagraphBlock ${className}`}>
      <span dangerouslySetInnerHTML={{ __html: text }}></span>
    </div>
  )
}

TextParagraphComponent.propTypes = {
  text: PropTypes.string.isRequired,  
  className: PropTypes.string,  
};

TextParagraphComponent.defaultProps = {
  text: '',
  className: '',
};

const TextParagraphsComponent = ({content, className}) => {
  return (
    <div className={`TextParagraphsComponent d-flex flex-row flex-wrap ${className}`}>
      {content.map((data, index) => {
        return (
          <div key={index}>
            <TextParagraphComponent text={data}/>
          </div>
        )
      })}
    </div>
  )
};

TextParagraphsComponent.propTypes = {
  content: PropTypes.arrayOf(PropTypes.string).isRequired,
  className: PropTypes.string,
  classNameInside: PropTypes.string,
};

const TitleComponent = (props) => {
  return (
    <div className="TitleComponent d-flex flex-column justify-content-start ">
      <div>
        <span>
          {props.title}
        </span>
      </div>
      <div className="title-horizontal-line mt-4"></div>
    </div>
  )
}

TitleComponent.propTypes = {
  title: PropTypes.string.isRequired, // Ожидаем строку и делаем ее обязательной
};

const SmallTitleComponent = ({tytle, className}) => {
  return (
    <div className={`SmallTitleComponent d-flex flex-column justify-content-center ${className}`} >
      <div>
        <span>
          {tytle}
        </span>
      </div>
    </div>
  )
}

SmallTitleComponent.propTypes = {
  tytle: PropTypes.string.isRequired,
  className: PropTypes.string
};

const ImgComponent = (props) => {
  return (
    <div className="ImgComponentBlock">
      <img src={props.path}></img>      
    </div>
  )
}

ImgComponent.propTypes = {
  path: PropTypes.string.isRequired,
};

const CardComponent = (props) => {
  return (
    <div className="CardsComponent d-flex flex-row flex-wrap">
      {props.cardsData.map((data, index) => {
        const card = data.basicInfo
        return (
          <Link key={index} to={card.link} className="card CardComponent col-md-2 text-decoration-none CardComponentWith175">
            <div className="card-inner">
              <img src={card.imgPath} className="card-img-top">
              </img>
              <div className="card-body">
                <span className="card-title">
                  {card.title}
                </span>
                <span className="card-text">
                  {card.text}
                </span>
              </div>
            </div>
          </Link>
        )
      })}
    </div>
  )
};

const CourseCardComponent = (props) => {
  return (
    <div className="CardsComponent d-flex flex-row flex-wrap justify-content-around">
      {props.cardsData.map((card, index) => {
        return (
          <Link key={index} to={card.link} className="card CardComponent col-md-2 text-decoration-none CardComponentWith">
            <div className="card-inner">
              <img src={card.imgPath} className="card-img-top">
              </img>
              <div className="card-body">
                <span className="card-title">
                  {card.title}
                </span>
                <div>
                    <span className="card-text">
                      {card.eventTypeName}
                    </span>
                    <span className="card-text">
                      {card.eventType}
                    </span>
                  </div>
                  <div>
                    <span className="card-text">
                      {card.lecturerName}
                    </span>
                    <span className="card-text">
                      {card.lecturer}
                    </span>
                  </div>
                <div className='d-flex flex-row justify-content-between'>
                  <div>
                    <span className="card-text">
                      {card.scoreBPRName}
                    </span>
                    <span className="card-text">
                      {card.scoreBPR}
                    </span>
                  </div>
                  <div>
                    <span className="card-text">
                      {card.dateName}
                    </span>
                    <span className="card-text">
                      {card.date}
                    </span>
                  </div>
                  <div>
                    <span className="card-text">
                      {card.priseName}
                    </span>
                    <span className="card-text">
                      {card.prise}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        )
      })}
    </div>
  )
};

CardComponent.propTypes = {
  cardsData: PropTypes.array.isRequired,
};

const TextBlocksComponent = (props) => {
  return (
    <div className="text-blocks-component d-flex flex-row flex-wrap">
      {props.content.map((data, index) => (
        <Link key={index} to={data.link} className="text-block-component text-start">
          <span className="text-with-marker">{data.title}</span>
        </Link>
      ))}
    </div>
  );
};

TextBlocksComponent.propTypes = {
  content: PropTypes.array.isRequired,
};

const ButtonComponent = ({ text, onClick, link, className }) => {
  // Если предоставлена ссылка, используется элемент Link, иначе - обычный button
  const ButtonElement = link ? Link : 'button';

  return (
    <ButtonElement
      to={link || '#'} // Если это Link, то устанавливается атрибут to
      onClick={onClick} // Если это button, то используется обработчик onClick
      className={`btn button-style text-start ${className}`}
    >
      {text}
    </ButtonElement>
  );
};

ButtonComponent.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  link: PropTypes.string,
  className: PropTypes.string,
};

ButtonComponent.defaultProps = {
  onClick: null,
  link: null,
  className: '',
};

const CreatePriceComponent = ({ text, val, currency, imagePath, className }) => {
  if (!currency) currency = '';
  return(
    <div className={`label-with-icon ${className}`}>
      {imagePath && <img src={imagePath} alt="img" />}
      <span>{`${text}: ${val}${currency}`}</span>
    </div>
  )
};

CreatePriceComponent.propTypes = {
  text: PropTypes.string,
  val: PropTypes.any,
  currency: PropTypes.string,
  imagePath: PropTypes.string,
  className: PropTypes.string,
};

CreatePriceComponent.defaultProps = {
  text:'',
  val: '',
  currency: '',
  imagePath: '',
  className: '',
};

const LabelWithIconComponent = ({ text, imagePath, href, className }) => {
  return (
    <a href={href} className={`label-with-icon ${className}`}>
      {imagePath && <img src={imagePath} alt="img" />}
      <span>{text}</span>
    </a>
  );
};

LabelWithIconComponent.propTypes = {
  text: PropTypes.string.isRequired,
  imagePath: PropTypes.string,
  href: PropTypes.string,
  className: PropTypes.string,
};

const TextParagraphComponentNumbered = ({ text, number }) => {
  return (
    <div className={`TextParagraphComponentNumbered`}>
      <span className="paragraph-number">{number}. </span>
      <span className="paragraph-text">{text}</span>
    </div>
  );
};

TextParagraphComponentNumbered.propTypes = {
  text: PropTypes.string.isRequired,
  number: PropTypes.number.isRequired,
};

const TextParagraphsComponentNumbered = ({ content, className }) => {
  return (
    <div className={`TextParagraphComponentNumbered d-flex flex-column ${className}`}>
      {content.map((text, index) => {
        return (
          <TextParagraphComponentNumbered key={index} text={text} number={index + 1} />
        )
      })}
    </div>
  )
};

TextParagraphsComponentNumbered.propTypes = {
  content: PropTypes.arrayOf(PropTypes.string).isRequired,
  className: PropTypes.string,
};

const InfoBlockComponent = ({ title, content, className }) => {
  return (
    <div className={`InfoBlockComponent ${className}`}>
      <h2 className="info-block-title">{title}</h2>
      <div className="info-block-content">
        {content.map((paragraph, index) => (
          <p key={index} className="info-block-paragraph">{paragraph}</p>
        ))}
      </div>
    </div>
  );
};

InfoBlockComponent.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.arrayOf(PropTypes.string).isRequired,
  className: PropTypes.string,
};

const NumberedLinkListComponent = ({ content, className }) => {
  return (
    <div className={`NumberedLinkListComponent-list ${className}`}>
      {content.map((document, index) => (
        <a key={index} href={document.link} className="NumberedLinkListComponent-item" target="_blank" rel="noopener noreferrer">
          <span className="NumberedLinkListComponent-index">{index + 1}.</span>
          <span className="NumberedLinkListComponent-title">{document.title}</span>
        </a>
      ))}
    </div>
  );
};

NumberedLinkListComponent.propTypes = {
  content: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
  })).isRequired,
  className: PropTypes.string,
};

const SmallTitleAndParagraphBlock = ({tytle, smallTitleClassName, content, textParagraphsClassName, className}) => {

  if(!content || Array.isArray(content) && !content.length) return

  return(
    <div className={`${className}`}>
      <SmallTitleComponent tytle={tytle} className={smallTitleClassName}/>
      <TextParagraphsComponent content={content} className={textParagraphsClassName} />
    </div>
  )
};


SmallTitleAndParagraphBlock.propTypes = {
  title: PropTypes.string.isRequired,
  smallTitleClassName: PropTypes.string,
  content: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]),
  textParagraphsClassName: PropTypes.string,
  className: PropTypes.string
};

SmallTitleAndParagraphBlock.defaultProps = {
  content: null,
  smallTitleClassName: '',
  textParagraphsClassName: '',
  className: ''
};


export default {
  TextOnGreenFieldComponent, 
  TextParagraphComponent,
  TextParagraphsComponent,
  TitleComponent,
  SmallTitleComponent,
  ImgComponent,
  CardComponent,
  TextBlocksComponent,
  ButtonComponent,
  CreatePriceComponent,
  LabelWithIconComponent,
  TextParagraphsComponentNumbered,
  InfoBlockComponent,
  NumberedLinkListComponent,
  SmallTitleAndParagraphBlock,
  CourseCardComponent,
}
