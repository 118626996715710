import React from 'react';
import db from '../../store/db';
import TextComponents from "../PageComponents/TextComponents"
import './style.css';
import EmailRestAPI from "./EmailRestAPI"

const AboutUsComponent = () => {
    const aboutUs = db.pageData.aboutUs.aboutUsText
    return (
        <div className='mentorsBlock'>
            <div className='container'>
                <div>
                    <TextComponents.TitleComponent title='Реєстрація на курс'/>
                </div>
                <div className='container'>
                    <EmailRestAPI/>
                </div>
            </div>
        </div>
    );
};

export default AboutUsComponent;