// DynamicComponentLoader.js
import React from 'react';
import { useParams, Navigate } from 'react-router-dom';
import db from '../../store/db';
import TextComponents from "../PageComponents/TextComponents"
import './style.css';

const DirectionDetailComponent = () => {
    const { direction, id } = useParams();

  let componentData;
  
  db.directions.map((element) => {
    if (element.basicInfo.componentId === direction) {
        element.modules.map((el) => {
            if (el.module == id) {
                componentData = el;
            }
        });
    }
  });


  if (componentData.lecturer.text) {
    // Safe to access title
    console.log(componentData.lecturer.text);
  } else {
    // Handle the case where directionData or title is undefined
    console.log('Title is not defined');
  }

  if (!componentData) {
    return <Navigate to="/not-found" />;
  }

  return (
    <div>
      <div className='container d-flex flex-row justify-content-between'>
        <div className='col-md-6 flex-column'>
            <TextComponents.TitleComponent title={componentData.title}/>
            <div className='d-flex align-items-start flex-column DirectionDetailComponent-first-info-block'>
                <div>
                    <TextComponents.LabelWithIconComponent
                        text={`${componentData.lecturer.text} ${componentData.lecturer.name}`} 
                        imagePath={componentData.lecturer.imgPath} 
                        href={`/lecturers/${componentData.lecturer.componentId}`} 
                        className='DirectionDetailComponent-mentor-block'
                    />
                </div>
                <div>
                    <TextComponents.CreatePriceComponent 
                        text={componentData.price.text} 
                        val={componentData.price.val} 
                        currency={componentData.price.currency} 
                        imagePath={componentData.price.imgPath}
                        className='DirectionDetailComponent-price-block'
                    />
                </div>
                <div>
                    <TextComponents.CreatePriceComponent 
                        text={db.pageData.homePage.startDate.text} 
                        val={componentData.startDate} 
                        imagePath={db.pageData.homePage.startDate.imgPath}
                        className='DirectionDetailComponent-price-block'
                    />
                </div>
                <div>
                    <TextComponents.CreatePriceComponent 
                        text={componentData.score.text} 
                        val={componentData.score.val} 
                        currency={componentData.score.currency}
                        imagePath={componentData.score.imgPath}
                        className='DirectionDetailComponent-price-block'
                    />
                </div>
                <div>
                    <TextComponents.CreatePriceComponent 
                        text={componentData.numInMOZ.text} 
                        val={componentData.numInMOZ.val}
                        imagePath={componentData.numInMOZ.imgPath}
                        className='DirectionDetailComponent-price-block'
                    />
                </div>
            </div>
            
        </div>
        <div className='col-md-6 DirectionDetailComponent-imgInBlock'>
            <img src={componentData.imgPath} className="Lecturers-img"></img>
            <div className='DirectionDetailComponent-castom-size-batton-block'>
              <TextComponents.ButtonComponent
                  text="Реєстрація на курс"
                  link="/course-registration"
                  className="DirectionDetailComponent-castom-size"
              />
            </div>
          </div>
      </div>
      <div className='container d-flex flex-column justify-content-center'>
        <TextComponents.SmallTitleComponent tytle={componentData.textData.program.title} />
        <TextComponents.TextParagraphsComponentNumbered content={componentData.textData.program.data} />
      </div>
      <div className='container d-flex flex-column justify-content-center'>
        <TextComponents.SmallTitleComponent tytle={componentData.textData.goal.title} />
        <TextComponents.TextParagraphComponent text={componentData.textData.goal.data} />
      </div>
      <div className='container d-flex flex-column justify-content-center'>
        <TextComponents.InfoBlockComponent title={db.infoBlock.formFill.title} content={db.infoBlock.formFill.data} />
        <TextComponents.InfoBlockComponent title={db.infoBlock.howTolink.title} content={db.infoBlock.howTolink.data} />
        <TextComponents.InfoBlockComponent title={db.infoBlock.testControl.title} content={db.infoBlock.testControl.data} />
        <TextComponents.InfoBlockComponent title={db.infoBlock.howToSertificate.title} content={db.infoBlock.howToSertificate.data} />
      </div>
    </div>
  );
};

export default DirectionDetailComponent;
