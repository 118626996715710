import React from 'react';

const UpcomingEventsList = ({ events }) => {

  const upcomingEvents = events.slice(0, 20);

  return (
    <div className='UpcomingEventsList-block'>
      <ul>
        {upcomingEvents.map((event, index) => (
          <li key={index} className='upcomingEvents-li'>
            <strong>{event.date}</strong>: {event.name} <a href={event.link}>Детальніше</a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default UpcomingEventsList;
