import React from 'react';
import './style.css';
import TextComponents from '../PageComponents/TextComponents'
import DynamicCalendarComponent from '../DynamicCalendarComponent/DynamicCalendarComponent'
import { createEvents } from '../../helpers'

const CalendarComponent = () => {
    return (
        <div className='CalendarComponent-block'>
            <div className='container'>
                <div>
                    <TextComponents.TitleComponent title='Календар заходів'/>
                </div>
                <div className='calendar-block d-flex flex-column justify-content-between'>
                    <DynamicCalendarComponent events={createEvents()}/>
                </div>
            </div>
        </div>
    );
};

export default CalendarComponent;