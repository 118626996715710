import React from 'react';
import db from '../../store/db';
import TextComponents from "../PageComponents/TextComponents"
import './style.css';
import { createEvents } from '../../helpers'
import UpcomingEventsList from "../DynamicCalendarComponent/UpcomingEventsList"

const EventsComponent = () => {
    return (
        <div className='EventsComponent-block'>
            <div className='container'>
                <div>
                    <TextComponents.TitleComponent title={db.pageData.aboutUs.events.title}/>
                </div>
                <div className='d-flex flex-column justify-content-between'>
                    <TextComponents.CardComponent cardsData={db.directions} />
                </div>
            </div>
            <div className='container d-flex justify-content-between  flex-column'>
                <TextComponents.TitleComponent title='Найближчі заходи' />
                <div className='d-flex flex-column'>
                    <UpcomingEventsList events={createEvents()} />
                </div> 
            </div>
            <div className='container d-flex justify-content-between  flex-column'>
                <TextComponents.TitleComponent title={db.pageData.aboutUs.bottomText.title} />
                <TextComponents.TextParagraphsComponent
                    content={db.pageData.aboutUs.bottomText.content}
                    className='UserAgreementComponent-text HomeComponentBlock-bottom-text'
                />
            </div>
        </div>
    );
};

export default EventsComponent;