import React from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav, Container } from 'react-bootstrap';
import './stayle.css';
import db from '../../store/db';

const HeaderComponent = () => {
    return (
        <header>
            <Navbar collapseOnSelect expand="md" className="Navbar bg-light text-dark">
                <Container>
                    <Navbar.Brand as={Link} to="/" className="navbar-brand">
                        <img 
                            src="/logo512.png" 
                            alt="Eduprof" 
                            className="d-inline-block align-top logo-block-img"
                        />
                    </Navbar.Brand>
                    <div className="menu-block">
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="mr-auto">
                            {db.pageData.headerNavbarArr.map((data, index) => (
                                <Nav.Link key={index} as={Link} to={data.link} className="nav-link text-dark glow-on-hover">{data.title}</Nav.Link>
                            ))}
                            </Nav>
                        </Navbar.Collapse>
                    </div>
                </Container>
            </Navbar>
        </header>
    );
};

export default HeaderComponent;
