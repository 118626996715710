import React from 'react';
import db from '../../store/db';
import TextComponents from "../PageComponents/TextComponents"
import './style.css';

const lecturers = db.lecturers.map((element) => {
    return {
        basicInfo: {
            imgPath: element.basicInfo.imgPath,
            title: element.basicInfo.title,
            text: element.specialization.content[0],
            link: element.basicInfo.link
        }
    }
});

const LecturersComponent = () => {
  return (
    <div className='mentorsBlock'>
        <div className='container'>
            <div>
                <TextComponents.TitleComponent title='Наші ментори'/>
            </div>
            <div className='d-flex flex-column justify-content-between'>
                <TextComponents.CardComponent cardsData={lecturers} />
            </div>
        </div>
        {/* <div className='container'>
            <div>
                <TextComponents.SmallTitleComponent tytle='корисна ........'/>
            </div>
            <div className='d-flex flex-column justify-content-between'>
                <TextComponents.TextParagraphComponent text={db.pageData.homePage.bottomText.text1} />
            </div>
        </div> */}
        {/* <div className='container'>
            <div>
                <TextComponents.SmallTitleComponent tytle='стати ментором.....'/>
            </div>
            <div className='d-flex flex-column justify-content-center'>
                <div>
                    <TextComponents.ButtonComponent
                        text="Перейти на главную"
                        link="/"
                        className="LecturersComponent-castom-size"
                    />
                </div>
            </div>
        </div> */}
    </div>
  );
};

export default LecturersComponent;
