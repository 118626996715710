import React from 'react';
import './style.css';
import db from '../../store/db';
import TextComponents from "../PageComponents/TextComponents"

const FooterComponent = () => {
  return (
    <footer className="footer bg-light text-dark">
        <div className='container d-flex flex-row justify-content-between'>
            <div className='footerBlockContacts d-flex flex-column col-md-6'>
                <div className='d-flex justify-content-start'>
                    <h5>Контакти</h5>
                </div>
                <div className='flex-column'>
                    <div className='d-flex justify-content-start'>
                        <p>Телефонуйте: {db.pageData.aboutUs.phoneNumber}</p>
                    </div>
                    <div className='d-flex justify-content-start'>
                        <p> Пишіть: <a href={`'mailto:'${db.pageData.aboutUs.mail}`}>{db.pageData.aboutUs.mail}</a></p>
                    </div>
                    {/* <div className='d-flex justify-content-start'>
                        <p> Завітайте: {db.pageData.aboutUs.location}</p>
                    </div> */}
                </div>
                {/* <div className=" d-flex justify-content-start">
                    <h5>Ми в соцмережах:</h5>
                </div> */}
                {/* <div className='flex-column'>
                    <div className="social-links d-flex justify-content-start">
                        <a href={db.pageData.aboutUs.socialMedia.facebook} className="social-link" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faFacebookSquare} size="2x" />
                        </a>
                        <a href={db.pageData.aboutUs.socialMedia.instagram} className="social-link" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faInstagramSquare} size="2x" />
                        </a>
                        <a href={db.pageData.aboutUs.socialMedia.linkedin} className="social-link" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faLinkedin} size="2x" />
                        </a>
                    </div>
                </div> */}
            </div>
            <div className='footerBlockPopularDirections d-flex flex-column col-md-6'>
                <div className='d-flex justify-content-start'>
                    <h5>Інформація</h5>
                </div>
                <div className='flex-column'>
                    <TextComponents.TextBlocksComponent content={db.pageData.footerLinkArr} />
                </div>
            </div>
        </div>
    </footer>
  );
};

export default FooterComponent;
