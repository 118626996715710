import React from 'react';
import db from '../../store/db';
import TextComponents from "../PageComponents/TextComponents"
import './style.css';

const UserAgreementComponent = () => {
  return (
    <div className='container d-flex flex-column'>
        <div className='UserAgreementComponent-title'>
            <TextComponents.TitleComponent title={db.infoBlock.userAgreement.title}/>
        </div>
        <TextComponents.TextParagraphsComponent content={db.infoBlock.userAgreement.data} className='UserAgreementComponent-text' />

        <TextComponents.SmallTitleComponent tytle={db.infoBlock.useOfTheSite.title}/>
        <TextComponents.TextParagraphsComponent content={db.infoBlock.useOfTheSite.data} className='UserAgreementComponent-text' />
        
        <TextComponents.SmallTitleComponent tytle={db.infoBlock.disclaimer.title}/>
        <TextComponents.TextParagraphsComponent content={db.infoBlock.disclaimer.data} className='UserAgreementComponent-text' />

        <TextComponents.SmallTitleComponent tytle={db.infoBlock.registration.title}/>
        <TextComponents.TextParagraphsComponent content={db.infoBlock.registration.data} className='UserAgreementComponent-text' />

        <TextComponents.SmallTitleComponent tytle={db.infoBlock.feedbackAndComments.title}/>
        <TextComponents.TextParagraphsComponent content={db.infoBlock.feedbackAndComments.data} className='UserAgreementComponent-text' />

        <TextComponents.SmallTitleComponent tytle={db.infoBlock.useOfPersonalData.title}/>
        <TextComponents.TextParagraphsComponent content={db.infoBlock.useOfPersonalData.data} className='UserAgreementComponent-text' />
    </div>
  );
};

export default UserAgreementComponent;
