import axios from 'axios';
import React, { useState } from 'react'
import ReCAPTCHA from "react-google-recaptcha";
import { getListOfDirections } from '../../helpers'

const EmailRestAPI = () => {
  const directioData = getListOfDirections()

  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [capVal, setCapVal] = useState();
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [phone, setPhone] = useState('');
  const [birthDate, setBirthDate] = useState('');
  const [education, setEducation] = useState('');
  const [job, setJob] = useState('');
  const [occupiedPosition, setOccupiedPosition] = useState('');
  const [email, setEmail] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedModule, setSelectedModule] = useState('');
  
  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
    setSelectedModule(''); // Reset selected module when category changes
  };

  const handleModuleChange = (event) => {
    setSelectedModule(event.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsButtonClicked(true);
    // Your EmailJS service ID, template ID, and Public Key
    const serviceId = 'service_9xrp7v6';
    const userEmailTemplateId = 'template_x36w3rg';
    const teamEmailTemplateId = 'template_69tr7r9';
    const publicKey = 'jkjJ6ETFGFDbuDO3T';

    // Create an object with EmailJS service ID, template ID, Public Key, and Template params
    const userEmail = {
      service_id: serviceId,
      template_id: userEmailTemplateId,
      user_id: publicKey,
      template_params: {
        name,
        surname,
        middleName,
        email,
      }
    };
    const teamEmail = {
        service_id: serviceId,
        template_id: teamEmailTemplateId,
        user_id: publicKey,
        template_params: {
          name,
          surname,
          middleName,
          phone,
          birthDate,
          education,
          job,
          occupiedPosition,
          email,
          HRSelectedCategory: directioData[selectedCategory].name,
          selectedModule
        }
      };

    try {
      await axios.post("https://api.emailjs.com/api/v1.0/email/send", userEmail);
      setName('');
      setSurname('');
      setMiddleName('');
      setBirthDate('');
      setEducation('');
      setJob('');
      setOccupiedPosition('');
      setEmail('');
    } catch (error) {
      console.error(error);
    }
    try {
        await axios.post("https://api.emailjs.com/api/v1.0/email/send", teamEmail);
        setName('');
        setSurname('');
        setMiddleName('');
        setBirthDate('');
        setEducation('');
        setJob('');
        setOccupiedPosition('');
        setEmail('');
      } catch (error) {
        console.error(error);
      }
    alert('Email отправлен.');
    window.location.reload();
  }

  return (
    <div className='emailForm-block'>
      <form onSubmit={handleSubmit} className='emailForm'>
        <div>
            <span>Ім`я</span>
            <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
            />
        </div>
        <div>
            <span>Прізвище</span>
            <input
                type="text"
                value={surname}
                onChange={(e) => setSurname(e.target.value)}
                required
            />
        </div>
        <div>
            <span>По батькові</span>
            <input
                type="text"
                value={middleName}
                onChange={(e) => setMiddleName(e.target.value)}
                required
            />
        </div>
        <div>
            <span>Телефон</span>
            <input
                type="tel"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                required
            />
        </div>
        <div>
            <span>Дата народження</span>
            <input
                type="date"
                value={birthDate}
                onChange={(e) => setBirthDate(e.target.value)}
                required
            />
        </div>
        <div>
            <span>Освіта (рік закінчення та назва ВНЗ)</span>
            <input
                type="text"
                value={education}
                onChange={(e) => setEducation(e.target.value)}
                required
            />
        </div>
        <div>
            <span>Місце роботи (назва ЗОЗу)</span>
            <input
                type="text"
                value={job}
                onChange={(e) => setJob(e.target.value)}
                required
            />
        </div>
        <div>
            <span>Займана посада</span>
            <input
                type="text"
                value={occupiedPosition}
                onChange={(e) => setOccupiedPosition(e.target.value)}
                required
            />
        </div>
        <div>
            <span>Email</span>
            <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
            />
        </div>
        <div>
            <span>Напрямок</span>
            <select required value={selectedCategory} onChange={handleCategoryChange}>
              <option value="">Виберiть Напрямок</option>
              {Object.keys(directioData).map((categoryKey) => (
                <option key={categoryKey} value={categoryKey}>
                  {directioData[categoryKey].name}
                </option>
              ))}
            </select>
        </div>
        <div>
            <span>Назва курсу</span>
            <select required value={selectedModule} onChange={handleModuleChange} disabled={!selectedCategory}>
              <option value="">Виберiть Модуль</option>
              {selectedCategory &&
                directioData[selectedCategory].modules.map((module, index) => (
                  <option key={index} value={module}>
                    {module}
                  </option>
                ))}
            </select>
        </div>

        <ReCAPTCHA
          // sitekey="6LdKfHkpAAAAAF2IrOcKyGdPmjN4RQhezz5g3K7S" //local
          sitekey="6Lc03XkpAAAAADNyZWclDWQJv-wEWo-BOEwv_fH2" //server
          onChange={(val) => setCapVal(val)}
        />
        <button disabled={ isButtonClicked || !capVal || !name || !surname || !middleName || !phone || !birthDate || !education || !job || !occupiedPosition || !email || !selectedCategory || !selectedModule} type="submit">Send Email</button>
      </form>
    </div>
  )
}

export default EmailRestAPI