import React from 'react';
import db from '../../store/db';
import TextComponents from "../PageComponents/TextComponents"
import './style.css';

const PaymentAndServiceComponent = () => {
    const data = db.infoBlock.paymentAndService
  return (
    <div className='container d-flex flex-column'>
        <div className='PaymentAndServiceComponent-title'>
            <TextComponents.TitleComponent title={data.title}/>
        </div>

        <TextComponents.SmallTitleComponent tytle={data.useOfPersonalData.title}/>
        <TextComponents.TextParagraphsComponent content={data.useOfPersonalData.data} className='UserAgreementComponent-text' />

        <TextComponents.SmallTitleComponent tytle={data.serviceProvision.title}/>
        <TextComponents.TextParagraphsComponent content={data.serviceProvision.data} className='UserAgreementComponent-text' />

        <div className='PaymentAndServiceComponent-title'>
            <TextComponents.TitleComponent title={data.obligationsOfTheParties.title}/>
        </div>
        <TextComponents.SmallTitleComponent tytle={data.obligationsOfTheParties.data.dutiesOfTheExecutor.title}/>
        <TextComponents.TextParagraphsComponent content={data.obligationsOfTheParties.data.dutiesOfTheExecutor.data} className='UserAgreementComponent-text' />

        <TextComponents.SmallTitleComponent tytle={data.obligationsOfTheParties.data.useOfPersonalData.title}/>
        <TextComponents.TextParagraphsComponent content={data.obligationsOfTheParties.data.useOfPersonalData.data} className='UserAgreementComponent-text' />
    </div>
  );
};

export default PaymentAndServiceComponent;
