// DynamicComponentLoader.js
import React from 'react';
import { useParams, Navigate } from 'react-router-dom';
import db from '../../store/db';
import TextComponents from "../PageComponents/TextComponents"
import UpcomingEventsList from "../DynamicCalendarComponent/UpcomingEventsList"
import './style.css';
import { createEvents } from '../../helpers'

const DirectionsComponentLoader = () => {
  const { direction } = useParams(); // предполагается, что в URL есть параметр, например, /component/:componentId

  let componentData;
  
  db.directions.map((element) => {
    if (element.basicInfo.componentId === direction) {
      componentData = element;
    }
  });

  if (!componentData) {
    return <Navigate to="/not-found" />;
  }

  const cardsData = componentData.modules.map((element) => {
    return {
      title: element.title,
      imgPath: element.imgPath,
      link: `${componentData.basicInfo.link}/${element.module}`,
      eventTypeName: 'Тип івенту' + ': ',
      eventType: componentData.basicInfo.eventDirection,
      lecturerName: 'Ментор' + ': ',
      lecturer: element.lecturer.name,
      scoreBPRName: element.score.currency + ': ',
      scoreBPR: element.score.val,
      dateName: 'Дата' + ': ',
      date: element.startDate,
      priseName: element.price.currency + ': ',
      prise: element.price.val
    }
  });

  return (
    <div>
      <div className='container DirectionsComponentLoader'>
        <TextComponents.TitleComponent title={componentData.basicInfo.largeTitle}/>
      </div>
      <div className='container d-flex justify-content-around'>
        <TextComponents.CourseCardComponent cardsData={cardsData} />
        {/* <div className='DirectionsComponentLoaderMainPart'>
            
        </div>         */}
      </div>
      <div className='container d-flex justify-content-between  flex-column'>
          <TextComponents.TitleComponent title='Найближчі заходи' />
          <div className='d-flex flex-column'>
              <UpcomingEventsList events={createEvents([componentData])} />
          </div> 
      </div>
      <div className='container d-flex justify-content-between  flex-column'>
          <TextComponents.TitleComponent title={db.pageData.aboutUs.bottomText.title} />
          <TextComponents.TextParagraphsComponent
              content={db.pageData.aboutUs.bottomText.content}
              className='UserAgreementComponent-text HomeComponentBlock-bottom-text'
          />
      </div>
    </div>
  );
};

export default DirectionsComponentLoader;
