// DynamicComponentLoader.js
import React from 'react';
import { useParams, Navigate } from 'react-router-dom';
import db from '../../store/db';
import TextComponents from "../PageComponents/TextComponents"
import './style.css';

const LinkPageComponent = () => {
  const { linkComponentId } = useParams(); // предполагается, что в URL есть параметр, например, /component/:componentId

  let componentData;

  if( db.infoBlock.dataBPR[linkComponentId] ) componentData = db.infoBlock.dataBPR[linkComponentId]

  if (!componentData) {
    return <Navigate to="/not-found" />;
  }

  return (
    <div className='LinkPageComponent-block'>
      <div className='container'>
        <TextComponents.TitleComponent title={componentData.title}/>
      </div>
      <div className='container d-flex flex-column LinkPageComponent-block-links'>
        <TextComponents.NumberedLinkListComponent content={componentData.data} className='' />
      </div>
    </div>
  );
};

export default LinkPageComponent;
