import React, { useState, useEffect } from 'react';
import './style.css';
import db from '../../store/db';

const CalendarComponent = ({events}) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [days, setDays] = useState([]);
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  useEffect(() => {
    generateCalendarDays(currentYear, currentMonth);
  }, [currentYear, currentMonth]);

  const generateCalendarDays = (year, month) => {

    const startDay = new Date(year, month, 1).getDay();
    const daysInMonth = new Date(year, month + 1, 0).getDate();

    const daysArray = new Array(daysInMonth + startDay).fill(null).map((_, index) => {
      const day = index - startDay + 1;
      const dateString = `${String(day).padStart(2, '0')}.${String(month + 1).padStart(2, '0')}.${year}`;
      return {
        day: index >= startDay ? day : null,
        events: events.filter(event => event.date === dateString),
      };
    });
    setDays(daysArray);
  };

  const handleMonthChange = (e) => {
    setCurrentMonth(parseInt(e.target.value));
    setCurrentYear(currentDate.getFullYear());
  };

  const renderCalendarDays = () => {
    const calendarRows = [];
    let week = [];

    days.forEach((day, index) => {
      week.push(
        <td key={index} className='CalendarComponent-cell'>
          <div className='CalendarComponent-day-num'>
            <span>
              {day.day}
            </span>
          </div>
          {day.events.map((event, idx) => (
            <div key={idx} className='CalendarComponent-event'>
              <div>
                <div>
                  <span>{event.name} </span>
                </div>
                <div>
                  <a href={event.link}>{db.pageData.homePage.helpers.moreDetails}</a>
                </div>
              </div>
            </div>
          ))}
        </td>
      );

      if ((index + 1) % 7 === 0 || index === days.length - 1) {
        calendarRows.push(<tr key={index}>{week}</tr>);
        week = [];
      }
    });

    return calendarRows;
  };

  return (
    <div>
      <div className='calendar-header'>
        <select value={currentMonth} onChange={handleMonthChange}>
          {Array.from({ length: 12 }, (_, index) => (
            <option key={index} value={index}>
              {new Date(currentYear, index).toLocaleString('default', { month: 'long' })}
            </option>
          ))}
        </select>
        <span>{currentYear}</span>
      </div>
      <div>
        <table className='calander-castom-events'>
          <thead>
            {/* Заголовки дней недели */}
          </thead>
          <tbody>
            {renderCalendarDays()}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CalendarComponent;
