import React from 'react';
import db from '../../store/db';
import TextComponents from "../PageComponents/TextComponents"
import './style.css';

const docks = db.infoBlock.dataBPR.docks
const AboutUsComponent = () => {
    const aboutUs = db.pageData.aboutUs.aboutUsText
    return (
        <div className='mentorsBlock'>
            <div className='container'>
                <div>
                    <TextComponents.TitleComponent title={aboutUs.title}/>
                </div>
                <div className='d-flex flex-column justify-content-between'>
                    <TextComponents.TextParagraphsComponent
                        content={aboutUs.content}
                        className='UserAgreementComponent-text'
                    />
                </div>
            </div>
            <div className='container'>
                <TextComponents.TitleComponent title={docks.title}/>
            </div>
            <div className='container d-flex flex-column LinkPageComponent-block-links'>
                <TextComponents.NumberedLinkListComponent content={docks.data} className='' />
            </div>
        </div>
    );
};

export default AboutUsComponent;