import db from './store/db';

export function createEvents(componentData) {
    const events = [];
    const data = componentData || db.directions

    data.forEach((element) => {
        element.modules.forEach((el) => {
            events.push({
                date: el.startDate,
                name: el.title,
                link: `${element.basicInfo.link}/${el.module}`
            })
        })
    })

    return events;
}

export function getListOfDirections(componentData) {
    const directions = {};
    const data = componentData || db.directions

    data.forEach((element) => {
        directions[element.basicInfo.componentId] = {
            name: element.basicInfo.title,
            modules: [],
        }
        element.modules.forEach((el) => {
            directions[element.basicInfo.componentId].modules.push(el.title)
        })
    })

    return directions;
}