import React from 'react';
import './stayle.css'
import TextComponents from "../PageComponents/TextComponents"
import UpcomingEventsList from "../DynamicCalendarComponent/UpcomingEventsList"
import db from '../../store/db';
import { createEvents } from '../../helpers'

const HomeComponent = () => {
    const lecturers = db.lecturers.map((element) => {
        return {
            basicInfo: {
                imgPath: element.basicInfo.imgPath,
                title: element.basicInfo.title,
                text: element.specialization.content[0],
                link: element.basicInfo.link
            }
        }
    });
    
    return (
        <div className='HomeComponentBlock'>
            <div className='container d-flex flex-row'>
                <div className='d-flex flex-row justify-content-between flex-wrap'>
                    <div className='col-md-6'>
                        <TextComponents.TitleComponent title={db.pageData.aboutUs.aboutUsText.title}/>
                        <TextComponents.TextParagraphsComponent
                            content={db.pageData.aboutUs.aboutUsText.content}
                            className='UserAgreementComponent-text'
                        />
                    </div>
                    <div className='col-md-6'>
                        <TextComponents.ImgComponent path='/media/home.webp' />
                    </div>
                </div>
            </div>
            <div className='container d-flex justify-content-between  flex-column'>
                <TextComponents.TitleComponent title='Напрямки заходів' />
                <div className='d-flex flex-column'>
                    <TextComponents.CardComponent cardsData={db.directions} />
                </div> 
            </div>
            <div className='container d-flex justify-content-between  flex-column'>
                <TextComponents.TitleComponent title='Найближчі заходи' />
                <div className='d-flex flex-column'>
                    <UpcomingEventsList events={createEvents()} />
                </div> 
            </div>
            <div className='mentorsBlock container d-flex justify-content-between flex-column'>
                <TextComponents.TitleComponent title='Наші ментори'/>
                <div className='d-flex flex-column'>
                    <TextComponents.CardComponent cardsData={lecturers} />
                </div>
            </div>
            <div className='container d-flex justify-content-between  flex-column'>
                <TextComponents.TitleComponent title={db.pageData.aboutUs.bottomText.title} />
                <TextComponents.TextParagraphsComponent
                    content={db.pageData.aboutUs.bottomText.content}
                    className='UserAgreementComponent-text HomeComponentBlock-bottom-text'
                />
            </div>
        </div>
    );
};

export default HomeComponent;
