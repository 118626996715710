import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import HeaderComponent from './components/HeaderComponent/HeaderComponent';
import FooterComponent from './components/FooterComponent/FooterComponent';
import HomeComponent from './components/HomeComponent/HomeComponent';
import DirectionsComponentLoader from './components/DynamicComponents/DirectionsComponentLoader';
import DirectionDetailComponent from './components/DynamicComponents/DirectionDetailComponent';
import LecturersComponentLoader from './components/DynamicComponents/LecturersComponentLoader'
import LecturersComponent from './components/LecturersComponent/LecturersComponent'
import AboutUsComponent from './components/AboutUsComponent/AboutUsComponent'
import UserAgreementComponent from './components/UserAgreementComponent/userAgreementComponent'
import PaymentAndServiceComponent from './components/PaymentAndServiceComponent/PaymentAndServiceComponent'
import LinkPageComponent from './components/BPRComponents/BPRLinkPageComponent'
import EventsComponent from './components/EventsComponent/EventsComponent'
import CalendarComponent from './components/CalendarComponent/CalendarComponent'
import ScrollToTop from './components/PageComponents/ScrollToTop'
import CourseRegistrationComponent from './components/CourseRegistrationComponent/CourseRegistrationComponent'

function App() {
  return (
    <Router>
      <ScrollToTop />
      <div className="App">
        <HeaderComponent />
        <Routes className='mainComponent'>
          <Route path="/" element={<HomeComponent />} />
          <Route path="/lecturers" element={<LecturersComponent />} />
          <Route path="/about-us" element={<AboutUsComponent />} />
          <Route path="/user-agreement" element={<UserAgreementComponent />} />
          <Route path="/payment-and-service" element={<PaymentAndServiceComponent />} />
          <Route path="/events" element={<EventsComponent />} />
          <Route path="/calendar" element={<CalendarComponent />} />
          <Route path="/course-registration" element={<CourseRegistrationComponent />} />

          <Route path="/directions/:direction" element={<DirectionsComponentLoader />} />
          <Route path="/directions/:direction/:id" element={<DirectionDetailComponent />} />
          <Route path="/lecturers/:lecturer" element={<LecturersComponentLoader />} />

          <Route path="/bpr/:linkComponentId" element={<LinkPageComponent />} />
        </Routes>
        <FooterComponent />
      </div>
    </Router>
  );
}

export default App;
