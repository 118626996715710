// DynamicComponentLoader.js
import React from 'react';
import { useParams, Navigate } from 'react-router-dom';
import db from '../../store/db';
import TextComponents from "../PageComponents/TextComponents"
import './style.css';

const LecturersComponentLoader = () => {
  const { lecturer } = useParams(); // предполагается, что в URL есть параметр, например, /component/:componentId

  let componentData;
  
  db.lecturers.map((element) => {
    if (element.basicInfo.componentId === lecturer) {
      componentData = element;
    }
  });

  if (!componentData) {
    return <Navigate to="/not-found" />;
  }

  return (
    <div>
      <div className='container LecturersComponentLoader'>
        <TextComponents.TitleComponent title={componentData.basicInfo.title}/>
      </div>
      <div className='container d-flex flex-row'>
        <div className='d-flex flex-column'>
          <div className='d-flex flex-row justify-content-between'>
            <div className='col-md-3'>
              <img src={componentData.basicInfo.imgPath} className="Lecturers-img"></img>
            </div>
            <div className='col-md-9 justify-content-start LecturersComponentLoader-text-decorations'>
              <TextComponents.SmallTitleAndParagraphBlock 
                tytle={componentData.position.title}
                smallTitleClassName='LecturersComponentLoader-small-title-modifay'
                content={componentData.position.content}
                className='justify-content-start'
              />

              <TextComponents.SmallTitleAndParagraphBlock 
                tytle={componentData.career.title}
                smallTitleClassName='LecturersComponentLoader-small-title-modifay'
                content={componentData.career.content}
                className='justify-content-start'
              />
              <TextComponents.SmallTitleAndParagraphBlock 
                tytle={componentData.degree.title}
                smallTitleClassName='LecturersComponentLoader-small-title-modifay'
                content={componentData.degree.content}
                className='justify-content-start'
              />
            </div>
          </div>
          <div className='d-flex flex-column'>
            <div className='LecturersComponentLoader-add-margin-bottom'>
              <TextComponents.SmallTitleAndParagraphBlock 
                tytle={componentData.experience.title}
                smallTitleClassName='LecturersComponentLoader-small-title-modifay'
                content={componentData.experience.content}
                className='justify-content-start'
              />
              <TextComponents.SmallTitleAndParagraphBlock 
                tytle={componentData.dissertation.title}
                smallTitleClassName='LecturersComponentLoader-small-title-modifay'
                content={componentData.dissertation.content}
                className='justify-content-start'
              />
              <TextComponents.SmallTitleAndParagraphBlock 
                tytle={componentData.honors.title}
                smallTitleClassName='LecturersComponentLoader-small-title-modifay'
                content={componentData.honors.content}
                className='justify-content-start'
              />
              <TextComponents.SmallTitleAndParagraphBlock 
                tytle={componentData.certification.title}
                smallTitleClassName='LecturersComponentLoader-small-title-modifay'
                content={componentData.certification.content}
                className='justify-content-start'
              />
              <TextComponents.SmallTitleAndParagraphBlock 
                tytle={componentData.research.title}
                smallTitleClassName='LecturersComponentLoader-small-title-modifay'
                content={componentData.research.content}
                className='justify-content-start'
              />
            </div>
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default LecturersComponentLoader;
